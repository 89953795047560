<template>
    <base-layout>
        <div class="page-fibre-products container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Fibre Products</h1>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <app-table
                        class="fibre-products-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="products"

                        :loading="loading"
                    />

                    <app-pagination
                        v-if="pagination.total"

                        v-model="pagination.page"

                        :total="pagination.total"
                        :limit="pagination.limit"
                        :page-range="pagination.range"

                        @change="onPageChange"
                    />
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import baseLayout from '@/views/layouts/base-layout'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import formatDateNano from '@/helpers/format-date-nano'

export default {
    components: {
        appTable,
        appPagination,
        baseLayout,
    },

    data() {
        return {
            loading: false,

            filter: {
                sort: {
                    field: 'CreatedAt',
                    order: 'asc'
                },
            },

            sort: [
                { field: 'MobileNumber', title: 'BSS account №' },
                { field: 'ProductType',  title: 'Product type'  },
                { field: 'Active',       title: 'Status'        },
                { field: 'CreatedAt',    title: 'Created at'    },
                { field: 'UpdatedAt',    title: 'Updated at'    },
            ],

            cols: [
                { key: 'UUID',         title: 'UUID',                                          highlight: true, },
                { key: 'bss-account',  title: 'BSS Account №', sort: { field: 'MobileNumber' }                  },
                { key: 'product-type', title: 'Product Type',  sort: { field: 'ProductType'  }                  },
                { key: 'status',       title: 'Status',        sort: { field: 'Active'       }, type: 'status', },
                { key: 'created-at',   title: 'Created At',    sort: { field: 'CreatedAt'    }                  },
                { key: 'updated-at',   title: 'Updated At',    sort: { field: 'UpdatedAt'    }                  },
            ],

            products: [],

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getFibreProducts()
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getFibreProducts()
        },

        onPageChange(page) {
            this.getFibreProducts(page)
        },

        getFibreProducts(page) {
            this.loading = true

            const filter = {
                ...this.filter,
            }

            const params = {
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     filter.sort.field,
                'SearchOptions.SortDesc':   filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('getFibreProducts', { params })
                .then(({ PageInfo, Products }) => {
                    this.products = Products.map(product => ({
                        uuid: product.UUID,
                        UUID: product.UUID,
                        'bss-account': product.MobileNumber,
                        'product-type': product.ProductType,
                        status: {
                            status: product.Active ? 'success' : 'danger',
                            text: product.Active ? 'Active' : 'Inactive',
                        },
                        'created-at': product.CreatedAt ? this.formatDateNano(product.CreatedAt) : '?',
                        'updated-at': product.UpdatedAt ? this.formatDateNano(product.UpdatedAt) : '?',
                    }))

                    const { DisplayPageList, PageNumber, TotalItemCount } = PageInfo

                    this.pagination.page = PageNumber
                    this.pagination.total = TotalItemCount || DisplayPageList.length * this.pagination.limit

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.products = []

                    this.loading = false
                })
        },

        fullname(firstname, lastname) {
            const fullname = [firstname, lastname].join(' ').trim()

            return fullname.length ? fullname : '?'
        },

        formatDateNano,
    }
}
</script>

<style lang="scss">
.page-fibre-products {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .fibre-products-table {
        @include table-cols-width((300px, 135px, 125px, 115px, 170px, 170px), true);
    }

    .app-pagination {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    .page-fibre-products {
        .fibre-products-table {
            @include table-cols-width((105px, 115px, 105px, 90px, 145px, 145px), true);
        }
    }
}

@media (max-width: $mobile-size) {
    .page-fibre-products {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .fibre-products-table {
            @include table-cols-width-mobile((95px, 180px), true);
        }

        .app-pagination {
            margin-top: 15px;
        }
    }
}
</style>